@import '../../Assets/less/style.less';
.veh_park_chkbox_pad{
    padding-top: 40px;
    @media only screen and (max-width:575px){
        &{
            padding-top: 0;
        }
    }
}

.v_color_display {
    margin-top: 11px,
}
.exis_tab{
    .ant-tabs-tab{
        margin: 0 12px 0 0;
        padding: 0;
        font-weight: 700;
        h4{
            color: @inactive;
        }
        .ant-tabs-tab-btn{
            text-shadow: none;
        }
    }
    .ant-tabs-tab-active{
        h4{
            color: @primary;
            font-weight: 700 !important;
        }
    }
    .ant-tabs-ink-bar{
        display: none;
    }
}
