@import '../../Assets/less/style.less';

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.abandoned_listing{
		td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Customer"; }
		td:nth-of-type(3):before { content: "Last Activity"; }
		td:nth-of-type(4):before { content: "Items On Cart"; }
        td:nth-of-type(5):before { content: "Total Amount"; }
        td:nth-of-type(6):before { content: "Reminder Status"; }
	}
}
