@import '../../Assets/less/style.less';
.notif-card{
    min-width: 336px;
    max-height: 305px;
    .ant-card-head{
      border-bottom: 0 !important;
    }
    .ant-card-head-wrapper{
      text-transform: uppercase;
      color: @secondary;
      font-weight: 500;
      .ant-card-extra{
        font-weight: 500;
      }
    }
    .ant-card-body{
      padding: 0 0 10px 0 !important;
      .notif-scroll{
        max-height:200px;
      }
    }
    .p-12{
      padding: 0 12px;
    }
    p{
      color: @inactive;
    }
    .not-border{
      border-top:1px solid @border-color;
    }
    .refund_dot{
      width: 8px !important;
      height: 8px !important;
    }
    .refund_wrap::after{
      height: 1px;
      width: calc(100% - 64px);
      background: #e3e3e3;
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .refund_wrap:last-child::after{
      display: none;
    }
    .ant-list-item-meta-title{
      margin-bottom: 0;
      line-height: normal;
    }
    .ant-list-item-meta{
      align-items: center;
    }
    .ant-list-item-meta-description{
      font-size: 12px;
      line-height: normal;
    }
    .ant-list-items{
      width: 100%;
    }
  }
  .not-active-bg{
    background: @inactive !important;
  }
  @media only screen and (max-width:575px){
      .resp_notif{
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: @white;
          z-index: 9999;
      }
      .notif-card{
        max-height: inherit;
        min-width: inherit;
        border: 0 !important;
        box-shadow: none !important;
        border-radius: inherit !important;
        .ant-card-head{
            margin: 10px 0;
        }
      }
      .notif_close{
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 99;
      }
      .notif-scroll{
         max-height: inherit !important;
        .ant-list-items{
          overflow-y: auto;
          height: calc(100vh - 120px);
        }
      }
      .notif-res-none{
        margin: 0 !important;
      }
  }
  
