@import '../../Assets/less/style.less';
.dash_exp_ov_scroll{
    height: 170px;
    overflow-y: auto;
}
.fac_cap_progress{
    height: 236px;
}

.dash_view_btn{
    min-width: 17px;
    width: 17px;
    height: 17px;
    line-height: 25px;
}
