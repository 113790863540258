@import '../../Assets/less/style.less';
.fac_detail_wrapper{
    .fac_fullscrn_icon{
        bottom: 24px;
        right: 24px;
    }
    .fac_img_wrapper{
        border-radius: @border-radius;
        overflow: hidden;
        img{
            height: 330px;
            object-fit: cover;
        }
    }
    .fac_srt_arw{
        @media only screen and (max-width:1560px){
            &::after{
            position: absolute;
            top: 24px;
            right: 0;
            }
        }
    }
    .coming-soon{
        top: 15%;
        left: 30%;
    }
}
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.fac_detail_listing{
		td:nth-of-type(1):before { content: "Timestamp"; }
		td:nth-of-type(2):before { content: "Vehicle"; }
		td:nth-of-type(3):before { content: "Contract ID"; }
		td:nth-of-type(4):before { content: "In/Out"; }
		td:nth-of-type(5):before { content: "Condition"; }
	}
}
