@import '../../style.less';
.login-box {
    max-width: 400px;
    margin: 3.5rem auto;
    // background-color: #fff;
    border-radius: 0.25rem;
    padding: 30px;
    align-items: center;
    .login-form{
        max-width: 331px;
        margin: 0 auto;
        height: 100%;
        label{
            font-weight: 500;
            color: @secondary;
        }
        .email_input{
            padding-right: 40px;
        }
        .rem_pad{
            padding-right: 11px !important;
        }
        .forg_pass .ant-form-item-control-input-content{
            display: flex;
            justify-content: space-between;
            a{
                color: @primary;
            }
            }
            .login_arw{
            background: @primary;
            border-color: @primary;
            min-width: 25px;
            width: 25px;
            height: 25px;
            line-height: 25px;
            top: 38px;
            right: 10px;
            z-index:1;
            &.pswd_arw{
                top: 38px;
            }
            .anticon-right{
                font-size: 10px;
            }
        }
        .login_close{
            top: 0px;
            right: 0px;
            color: @primary;
            .anticon-close{
                font-size: 10px;
            }
        }
        .email_icons{
            top: 45px;
            right: 8px !important;
            z-index: 10;
            .email_arw_icn{
                right: 0;
                top: -7px;
            }
        }
        .forgot_arw{
            background: @primary;
            border-color: @primary;
            min-width: 17px;
            width: 17px;
            height: 17px;
            line-height: 25px;
        }
        .login_input{
            background-color: @login-inp-bg !important;
            border-radius: 8px;
            height: 40px;
            &:hover,&:focus{
                border: 1px solid @border-color !important;
            }
            input{
                background: transparent !important;
                height: 100%;
            }
        }
        .ant-input-password{
            input{
                background: none;
                height: 100% !important;
            }
        }
    }
}

.result {
    font-size: 1.5rem;
    color: @result;
}
footer{
    bottom: 0;
    left: 0;
    right: 0;
    color: @secondary;
    background-color: @body-bg;
    position: absolute;
    @media only screen and (max-width:575px){
        position: relative;
    }
    a{
        color: @secondary;
    }
}