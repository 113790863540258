@import '../../Assets/less/style.less';
.metrices_wrap {
    padding-left: 60px;
    .radio_tab{
        max-width: 90px;
        border-radius: 25px !important;
        .brd_left_radius{
            border-radius: 25px 0 0 25px !important;
        }
        .brd_right_radius{
            border-radius: 0 25px 25px 0 !important;
        }
    }
}
