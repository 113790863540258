@import '../../style.less';
.create_wrapper{
    .back_link{
        color: @primary !important;
        box-shadow: none;
    }
    .create_image{
        height: 88px;
        width: 88px;
        background: @icn-bg;
        color: @secondary;
    }
    .create_para{
        color: @secondary;
    }
    .pro_pic_avatar:hover .img_upload{
        display: block;
    }
    .img_upload{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        display: none;
        .create_image{
            background: rgba(0,0,0,0.5);
            color: @gray;
        }
    }
    .gender_wrapper{
        padding-top: 34px;
        .radio_inp{
            input{
                width: 20px;
                height: 20px;
            }
        }
        @media only screen and (max-width:575px){
            &{
                padding-top: 0;
            }
        }
    }
    .create_card{
        .ant-card-body{
            padding-left: 0;
            padding-right: 0;
        }
        .create_border{
            border-bottom: 1px solid @border-color;
        }
        label{
            font-weight: 500;
            color: @secondary;
        }
    }
}
.document_wrapper{
    .doc_wrap{
        border-top: 1px solid @border-color;
        p{
            color: @secondary;
        }
    }
    .modal_doc_listing{
        border-bottom: 1px solid @border-color;
    }
}
.upload_text{
    font-size: 14px !important;
    color: @secondary !important;
}