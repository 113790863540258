@import '../../Assets/less/style.less';
.srt_arw_up {
    transform: rotateX(180deg);
    margin-left: 8px;
    color: @secondary;
}
.srt_arw_down{
    transform: rotateX(0deg);
    margin-left: 8px;
    color: @secondary;
}
.srt_arw_position{
    position: absolute;
    right: 0;
    margin-top: 6px;
}
@media only screen and (max-width:1600px) and (min-width:1200px){
    .srt_icon_p{
        padding-right: 20px;
        position: relative;
    }
    .srt_icon_padding{
        padding-right: 25px;
        position: relative;
    }
    .srt_icn_position{
        position: absolute;
        top: 5px;
        right: 0;
    }
}
@media only screen and (max-width:1500px) and (min-width:1200px){
    .srt_icn_position{
        right: 5px;
    }
}
@media only screen and (max-width:1440px) and (min-width:1330px){
    .srt_icon_padding{
        padding-right: 5px;
    }
}
@media only screen and (max-width:1330px) and (min-width:1200px){
    .srt_icon_padding{
        padding-right: 0px;
    }
}
@media only screen and (max-width:1320px) and (min-width:1200px){
    .srt_icon_p{
        padding-right: 15px;
    }
}

