@import '../../Assets/less/style.less';
#sidebar{
  position: fixed;
  overflow-y: auto;
  height: calc(100vh - 72px);
  left: 0;
  bottom: 0;
  z-index: 999;
  background: @white !important;
  overflow: hidden auto;
  .nav_sidebar{
    font-size: 16px;
    h6{
      padding-left:24px;
      font-size:14px;
      color: @secondary;
    }
    .side_icn{
      bottom: 2px;
    }
  }
  .ant-menu{
    background: @white;
    color: @inactive;
    border-right: 1px solid @border-color !important;
  }
  .ant-menu-item a{
    color: @inactive;
    height: 100%;
    display: inline-block;
    width: 100%;
    &:hover, &.active {
      color: @primary;
      font-weight: 700;
      }
  }
  .ant-menu-light .ant-menu-submenu-title:hover{
    color: @primary !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: inherit;
  }
  .ant-menu-sub .ant-menu-title-content{
    font-weight: 400 !important;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0;
  }
}
.sidebar_wdt{
  @media only screen and (max-width:1199px){
    &{
      width: 100% !important;
      height: 100% !important;
      z-index: 1999 !important;
      max-width: 300px !important;
    }
  }
  @media only screen and (max-width:530px){
    &{
      max-width: inherit !important;
    }
  }
}
// .ant-layout-sider{
//   background: @white !important;
//   overflow: hidden auto;
// }
.ant-menu-submenu-title,.ant-menu-submenu-arrow{
  color: @inactive !important;
}
.ant-menu-title-content{
  margin-left: 0 !important;
}
.ant-menu-submenu-open{
  color: @primary !important;
  .ant-menu-submenu-title,.ant-menu-submenu-arrow{
    color: @primary !important;
  }
  .ant-menu-title-content,.ant-menu-submenu-arrow{
    font-weight: 700 !important;
  }
  .ant-menu-title-content{
    a{
    margin-left: 25px !important;
    }
  }
}
@media only screen and (max-width:1199px){
  #side_bg_resp{
    width: 100%;
    position: fixed;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: none;
  }
}
