@import '../../Assets/less/style.less';
.coming-soon{
    top: 38%;
    left: 30%;
    color: rgb(0, 0, 0);
    z-index: 1;
}
.custom_plan_progress{
	width: 56px;
	.ant-progress-inner{
		padding: 1px;
	}
}

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.plan_listing{
		td:nth-of-type(1):before { content: "Plan ID"; }
		td:nth-of-type(2):before { content: "Plan Type"; }
		td:nth-of-type(3):before { content: "Plan Term"; }
		td:nth-of-type(4):before { content: "Client"; }
        td:nth-of-type(5):before { content: attr(data-heading); }
	}
}
