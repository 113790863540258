@import '../../Assets/less/style.less';
.error_wrapper{
    .ant-result-title{
        font-size: 44px;
        font-weight: 700;
        @media only screen and (max-width:1600px){
            &{
                font-size: 30px;
            }
        }
        @media only screen and (max-width:480px){
            &{
                font-size: 24px;
            }
        }
    }
    .ant-result-subtitle{
        font-size: 24px;
        color: @secondary;
        @media only screen and (max-width:1600px){
            &{
                font-size: 18px;
            }
        }
        @media only screen and (max-width:480px){
            &{
                font-size: 14px;
            }
        }
    }
}
