@import '../../Assets/less/style.less';
@media only screen and (max-width:575px){
    .resp_search{
        position: fixed !important;
        right: 0;
        left: 0;
        top: 13px;
        z-index: 9999;
        padding: 0 20px;
        background: #fff;
        bottom: 0;
        // overflow-y: scroll;
        display: flex;
        flex-direction: column;
        input{
            height: 45px;
        }
        .auto-cmp{
            width: 100% !important;
        }
    }
    body .body_scroll_none {
        overflow: hidden;
        height: 100vh;
    }
}
