@import '../../Assets/less/style.less';
.detail_card_wrapper{
    .contract_value{
        background: @off-white;
        padding: 6px 8px;
        color: @secondary;
    }
    .pending_signup{
        background: @warning-bg;
        color: @yellow;
        padding: 0 8px;
        height: 32px;
        width: max-content;
    }
}
