.color_picker_icn{
    top: 40px;
    right: 15px;
    color: #777777;
}

.rainbow_gradient{
background: red; /* For browsers that do not support gradients */
background: -webkit-linear-gradient(left, orange , yellow, green, cyan, blue, violet) !important; /* For Safari 5.1 to 6.0 */
background: -o-linear-gradient(right, orange, yellow, green, cyan, blue, violet) !important; /* For Opera 11.1 to 12.0 */
background: -moz-linear-gradient(right, orange, yellow, green, cyan, blue, violet) !important; /* For Firefox 3.6 to 15 */
background:  linear-gradient(to right, orange , yellow, green, cyan, blue, violet) !important; /* Standard syntax (must be last) */
}
.color_picker_none{
    .ant-form-item-control-input{
        display: none !important;
    }
}
.color_picker_show{
    .ant-form-item-control-input{
        display: block !important;
    }
}
