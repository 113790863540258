@import '../../Assets/less/style.less';

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.inventory_listing{
		td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Category"; }
		td:nth-of-type(3):before { content: "Product"; }
		td:nth-of-type(4):before { content: "Variant"; }
        td:nth-of-type(5):before { content: "Stock"; }
		td:nth-of-type(6):before { content: "Add"; }
		td:nth-of-type(7):before { content: "Remove"; }
	}
}

.filter-drp-dwn{
    min-width: 200px;
}
