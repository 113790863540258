@import '../../Assets/less/style.less';

.approval_modal_wrapper{
	.ant-modal-footer{
		padding-bottom: 20px;
		padding-top: 0;
	}
}
.approval_price{
	.ant-form-item{
		margin: 0;
	}
	.ant-form-item-label{
		width: 100%;
		text-align: left;
		font-weight: 500;
		label{
			color: @secondary;
		}
	}
	.chkbox_style{
		margin-top: 0 !important;
	}
	h5{
		font-size: 24px;
		margin-bottom: 8px;
	}
}

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.approval_table_listing{
		td:nth-of-type(1):before { content: "Client"; }
		td:nth-of-type(2):before { content: "Request Date"; }
		td:nth-of-type(3):before { content: "Plan Type"; }
		td:nth-of-type(4):before { content: "Plan Term"; }
		td:nth-of-type(5):before { content: "Location"; }
        td:nth-of-type(6):before { content: "Action"; }
	}
    .employee_table_request{
		td:nth-of-type(1):before { content: "Employee"; }
		td:nth-of-type(2):before { content: "Request Date"; }
		td:nth-of-type(3):before { content: "Request"; }
		td:nth-of-type(4):before { content: "Action"; }
	}
}
