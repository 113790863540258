@import '../../Assets/less/style.less';

.client_listing_wrapper{
    .existing_clnt_link{
        color: @primary;
    }
}
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.client_listing{
		td:nth-of-type(1):before { content: "Client ID"; }
		td:nth-of-type(2):before { content: "Client Name"; }
		td:nth-of-type(3):before { content: "Phone Number"; }
		td:nth-of-type(4):before { content: "Joined Date"; }
		td:nth-of-type(5):before { content: "Plans"; }
        td:nth-of-type(6):before { content: "Status"; }
	}
}
