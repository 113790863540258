@import '../../Assets/less/style.less';
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.plan_history_listing{
		td:nth-of-type(1):before { content: "Plan ID"; }
		td:nth-of-type(2):before { content: "Plan Type"; }
		td:nth-of-type(3):before { content: "Plan Term"; }
		td:nth-of-type(4):before { content: "Client"; }
		td:nth-of-type(5):before { content: "	Closed Date"; }
	}
}
