@import '../../Assets/less/style.less';
#header{
  position: fixed;
  width: 100%;
  border-bottom: 1px solid @border-color;
  background-color: @header-bg;
  z-index: 1000;
  height: 72px;
  .header_logo{
    max-width: 250px;
    min-width: 250px;
    @media only screen and (max-width:1600px){
      &{
        max-width: 210px;
        min-width: 210px;
      }
    }
    @media only screen and (max-width:1199px){
      &{
        max-width: 210px;
        min-width: inherit;
      }
    }
  }
  .h_rgt_menu{
    &::after{
      border:0 !important;
    }
    .ant-menu-title-content{
      position: relative;
    }
    button{
      color: @primary;
    }
    .search_wrapper{
      .ant-select-item-option-active:not(.ant-select-item-option-disabled){
        background: inherit !important;
      }
    }
    .qr_code_btn{
      background: @icn-bg;
      border-radius: 30px;
      height: 44px;
      @media only screen and (max-width:1199px){
        &{
          border-radius: 50%;
          width: 44px;
        }
      }
    }
    .inp-ins-srch{
      top: 0;
      left: 15px;
      z-index: 10;
      color: @primary;
    }
    .inp-ins-close{
      top: 0;
      right: 15px;
      z-index: 10;
      color: @primary;
    }
    .forgot_arw{
      background: @primary;
      border-color: @primary;
      min-width: 17px;
      width: 17px;
      height: 17px;
      line-height: 25px;
    }
    .h_user_btn{
      height: 44px;
      width: 44px;
      background: @icn-bg;
      color: @secondary;
    }
    .ant-input-group-addon{
      display: none;
    }
    .auto-cmp{
      .ant-select-selector{
        width: 100%;
      }
      .serch_inp{
        input{
          border-radius: 30px;
          background-color: @input-bg;
          padding: 7px 30px 7px 40px;
        }
      }
    }
  }
  .h_nav_link{
    a{
      // border-bottom: 1px solid @border-color;
      color:@gray !important;
      position: relative;
      top: 12px;
      &:hover{
          color:@primary !important;
      }
    }
    &::after{
      // border-color:@primary !important;
      background: @primary;
      height: 3px;  
      border-radius: 8px 8px 0px 0px !important;
    }
    &:hover::after{
      border-color:@primary !important;
    }
    .active{
      color:@primary !important;
      font-weight: 700;
    }
  }
  .hdr_menu_list{
    .ant-spin-container{
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
  }
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.srch_drp_icn{
  height: 35px;
  min-width: 35px;
  background: #d4f7de;
  line-height: 1;
  color: @green;
}
.notif-card{
  .ant-card-head{
    border-bottom: 0 !important;
  }
  .ant-card-head-wrapper{
    text-transform: uppercase;
    color: @secondary;
    font-weight: 500;
    .ant-card-extra{
      font-weight: 500;
    }
  }
  .ant-card-body{
    padding: 0 0 10px 0;
  }
  .p-12{
    padding: 0 12px;
  }
  p{
    color: @inactive;
  }
  .not-border{
    border-top:1px solid @border-color;
  }
  .load-more{
    color: @secondary !important;
  }
  .refund_dot{
    width: 8px !important;
    height: 8px !important;
  }
  .refund_wrap::after{
    height: 1px;
    width: calc(100% - 64px);
    background: #e3e3e3;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .refund_wrap:last-child::after{
    display: none;
  }
  .ant-list-item-meta-title{
    margin-bottom: 0;
    line-height: normal;
  }
  .ant-list-item-meta{
    align-items: center;
  }
  .ant-list-item-meta-description{
    font-size: 12px;
    line-height: normal;
  }
  .ant-list-item{
    padding: 8px 12px;
  }
}
.ant-menu-horizontal{
  border-bottom: 0 !important;
}
.ant-layout-header{
  line-height: 69px;
}
.user_popup{
  min-width: 200px;
  top: 65px;
}
