@import '../../Assets/less/style.less';
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.facility_listing{
		td:nth-of-type(1):before { content: "Facility ID"; }
		td:nth-of-type(2):before { content: "Facility Name"; }
		td:nth-of-type(3):before { content: "Facility Location"; }
		td:nth-of-type(4):before { content: "Phone"; }
		td:nth-of-type(5):before { content: "Vehicles In Facility"; }
	}
}
