@import '../../Assets/less/style.less';
.manage_category_listing{
	.ant-form-item-explain-error{
		white-space: break-spaces;
	}
}

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.manage_category_listing{
		td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Category Name"; }
		td:nth-of-type(3):before { content: "Category Code"; }
		td:nth-of-type(4):before { content: "In Use"; }
        td:nth-of-type(5):before { content: "Action"; }
	}
}
