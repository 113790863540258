@import '../../Assets/less/style.less';
.progress_width{
    .ant-progress-inner{
      width: 200px !important;
      height: 200px !important;
      @media only screen and (max-width:1270px){
        &{
            width: 170px !important;
            height: 170px !important;
        }
      }
    }
}
