@import '../../Assets/less/style.less';

.scan_list_height{
    min-height: 700px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    @media only screen and (max-width:768px){
        &{
            min-height: inherit;
            max-height: inherit;
            border-right: 0 !important;
            border-top: 1px solid @border-color;
        }
    }
}
.scan_product_qr{
    border-radius: 24px;
    border: 4px solid @border-color;
}
