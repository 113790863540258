@import '../../Assets/less/style.less';
.veh_park_img{
    max-width: 65px;
    height: 47px;
}
.plan_box{
    width: 48px;
    height: 48px;
    font-size: 24px;
}
