@import '../../Assets/less/style.less';
.set-wrapper {
    .set_form{
        .btn_set_pwsd{
            top: 38px !important;
        }
        .btn_set_close{
            top: 34px !important;
        }
    }
}

