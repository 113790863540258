@import '../../Assets/less/style.less';
.d-page-model-wrap{
    height: 480px;
}
.detail_page_model{
    max-width: 420px;
}
.model_card_ht{
    min-height: 369px;
    max-height: 369px;
    @media only screen and (max-width:575px){
        min-height: 280px;
        max-height: 280px;
    }
}
