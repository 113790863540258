@import '../../Assets/less/style.less';
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.employee_listing{
		td:nth-of-type(1):before { content: "Employee ID"; }
		td:nth-of-type(2):before { content: "Employee Name"; }
		td:nth-of-type(3):before { content: "Role"; }
		td:nth-of-type(4):before { content: "Joined Date"; }
		td:nth-of-type(5):before { content: "Phone Number"; }
		td:nth-of-type(6):before { content: "Last Activity"; }
		td:nth-of-type(7):before { content: "Status"; }
	}
}
