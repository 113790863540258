@import '../../Assets/less/style.less';
.forgot_wrapper {
    .forgot_form{
        .forg_pass_content{
          color: @secondary;
        }
        .forgot_arrow{
          top: -7px !important;
          right: 0 !important;
        }
        .email_icons{
          top: 44px;
          right: 0;
          z-index: 10;
        }
    }
}

