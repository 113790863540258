@import '../../Assets/less/style.less';

.create-product-tab {
    .ant-tabs-nav-list {
        .ant-tabs-tab:not(:nth-last-child(2)):after {
            content: "-";
            margin-left: 12px;
            color: @secondary;
        }
    }
}
.max-ht-160{
    max-height: 160px;
}
.p_img_wrapper_ht{
    min-height: 320px;
    max-height: 320px;
    overflow: auto;
}
.varient-wrap{
    .varient-p-list{
        border-bottom: 1px solid @border-color;
        margin-bottom: 16px;
        padding-bottom: 16px;
        &:last-child{
            border-bottom: 0;
            margin: 0;
            padding: 0;
        }
    }
}
@media only screen and (min-width:1024px){
    .duplicate_listing{
        table{
            border-radius: 8px !important;
            overflow: hidden !important;
            .ant-table-thead,.ant-table-tbody{
                background-color: @off-white !important;
            }
        }
    }
}

@media only screen and (max-width:768px){
    .min-ht-150{
        min-height: 150px;
    }
}

@media only screen and (max-width:530px){
    .product_upload_img{
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.compatible_listing{
		td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Make"; }
		td:nth-of-type(3):before { content: "Model"; }
		td:nth-of-type(4):before { content: "Year"; }
        td:nth-of-type(5):before { content: "Action"; }
	}
    .duplicate_listing{
        td:nth-of-type(1):before { content: "Make"; }
		td:nth-of-type(2):before { content: "Model"; }
		td:nth-of-type(3):before { content: "Year"; }
    }
}
