@import '../../Assets/less/style.less';

.vehicle_breadcrumb{
    border-radius: 8px 8px 0 0;
}
.vehicle_db_table{
    .ant-table{
        border-radius: 0 0 8px 8px !important;
    }
    .ant-table-tbody{
        tr{
            td{
                padding: 8px 18px !important;
            }
        }
    }
} 
.compatible_menu{
    background: rgba(0, 66, 37, 0.08);
    color: rgba(0, 66, 37, 0.3);
}
.comp_modal_p{
    padding: 0 18px;
}
.compatible_modal_table{
    max-height: 300px;
    overflow-y: auto;
    .ant-table{
        box-shadow: none;
    }
}
