@import '../../style.less';

.listing_wrapper{
    .header_main{
        font-size: 44px;
        @media only screen and (max-width:1500px){
            &{
                font-size: 36px;
            }
        }
        border-bottom:1px solid @border-color;
    }
    .table-header{
        @media only screen and (max-width:1500px){
            &{
                font-size: 18px;
            }
        }
    }
}