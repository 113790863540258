@import '../../Assets/less/style.less';
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.service_listing{
		td:nth-of-type(1):before { content: "Service"; }
		td:nth-of-type(2):before { content: "Service Price"; }
		td:nth-of-type(3):before { content: "Delivery Charge"; }
		td:nth-of-type(4):before { content: "Plan Required"; }
		td:nth-of-type(5):before { content: "Action"; }
	}
}
