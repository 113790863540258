.sr_icon{
    // position: absolute;
    // right: 32px;
    // top: 32px;
    max-width: 130px;
    @media only screen and (max-width:767px){
        max-width: 80px;
        // right: 16px;
        // top: 16px;
    }
}
// .emp_sr_req{
//     position: relative;
//     .ant-card-body{
//         min-height: 315px;
//         align-items: end;
//         display: flex;
//         @media only screen and (max-width:767px){
//             min-height: 200px;
//         }
//     }
// }

.emp_card_height {
    min-height: 315px;
}
