@import '../../Assets/less/style.less';
.detail_card_wrapper{
    .contact_card_brd::before{
        height: 1px;
        width: calc( 100% - 40px);
        background: @border-color;
        position: absolute;
        top: 0;
        right: 0;
        content: '';
    }
}
