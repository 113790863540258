@import '../../Assets/less/style.less';
.manage_upload_img{
    width: 176px;
    height: 176px;
    .delete_model{
        width: 40px;
        height: 40px;
        bottom: 10px;
        right: 10px;
        background: rgba(255, 65, 65, 0.2);
    }
}
