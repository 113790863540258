@import '../../Assets/less/style.less';

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.shared_listing{
		td:nth-of-type(1):before { content: "Name"; }
		td:nth-of-type(2):before { content: "Email"; }
		td:nth-of-type(3):before { content: "Role"; }
		td:nth-of-type(4):before { content: "Status"; }
	}
}
