@import '../../Assets/less/style.less';

@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.approval_hist_listing{
		td:nth-of-type(1):before { content: "Client Name"; }
		td:nth-of-type(2):before { content: "Request Date"; }
		td:nth-of-type(3):before { content: "Plan Type"; }
		td:nth-of-type(4):before { content: "Plan Term"; }
		td:nth-of-type(5):before { content: "Location"; }
        td:nth-of-type(6):before { content: "Action"; }
	}
    .employee_hist_request{
		td:nth-of-type(1):before { content: "Employee"; }
		td:nth-of-type(2):before { content: "Request Date"; }
		td:nth-of-type(3):before { content: "Request"; }
		td:nth-of-type(4):before { content: "Action"; }
	}
}
