@import '../../Assets/less/style.less';
@media only screen and (max-width: 768px),(min-device-width: 768px) and (max-device-width: 1024px)  {
	.contract_act_listing{
		td:nth-of-type(1):before { content: "Activity"; }
		td:nth-of-type(2):before { content: "Action"; }
		td:nth-of-type(3):before { content: "User"; }
		td:nth-of-type(4):before { content: "Vehicle"; }
		td:nth-of-type(5):before { content: "Swapped Vehicle"; }
		td:nth-of-type(6):before { content: "Date"; }
		td:nth-of-type(7):before { content: "Time"; }
	}
}
