@import '../../style.less';
.detail_wrapper{
    .breadcrumb{
        border-bottom:1px solid @border-color;
    }
    .car_name,.car_location{
        color: @secondary;
    }
    .car_image{
        height: 264px;
        max-width: 300px;
        img{
            object-fit: contain;
        }
        @media only screen and (max-width:575px){
            &{
                height: auto !important;
                padding: 20px 0;
            }
        }
    }
    .car_colct_time{
        background: @off-white;
        color: @secondary;
        padding: 6px 8px;
    }
    .car_parking_heading{
        @media only screen and (max-width:1260px){
            &{
                font-size: 20px;
            }
        }
    }
}